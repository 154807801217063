import { AJAX_API } from '../utils/api';
import { defineModule } from '../utils/helpers';

let uniqueId = 0;
const currentPageMap = new Map();

const loadMore = async (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  const target = e.currentTarget.getAttribute('data-load-more-target');
  if (!target) return;

  const postType =
    e.currentTarget.getAttribute('data-load-more-post-type') ?? 'post';
  const perPage = parseInt(
    e.currentTarget.getAttribute('data-load-more-per-page') ?? '1',
  );
  const offset = parseInt(
    e.currentTarget.getAttribute('data-load-more-offset') ?? '0',
  );
  const orderBy = parseInt(
    e.currentTarget.getAttribute('data-load-more-order-by') ?? 'date',
  );
  const order = parseInt(
    e.currentTarget.getAttribute('data-load-more-order') ?? 'DESC',
  );
  const year = parseInt(
    e.currentTarget.getAttribute('data-load-more-year') ?? '',
  );

  const targetElement = document.querySelector<HTMLElement>(target);
  if (!targetElement) return;

  const hasIds = [targetElement, e.currentTarget].every((el) =>
    el.hasAttribute('data-load-more-id'),
  );

  if (!hasIds) {
    uniqueId += 1;
    [targetElement, e.currentTarget].forEach((el) => {
      el.setAttribute('data-load-more-id', uniqueId.toString());
    });
  }

  const id = e.currentTarget.getAttribute('data-load-more-id');
  if (!id) return;

  let currentPage = currentPageMap.get(id) ?? 0;
  currentPageMap.set(id, (currentPage += 1));

  try {
    const { max, count, html } = await AJAX_API.formData({
      action: 'custom_load_more',
      post_type: postType,
      posts_per_page: perPage,
      offset,
      page: currentPage,
      order_by: orderBy,
      order,
      year,
    })
      .post()
      .json<{ max: number; count: number; html: string }>();

    targetElement.insertAdjacentHTML('beforeend', html);

    if (!max || currentPage + 1 === max) {
      document
        .querySelectorAll<HTMLElement>(
          `[data-load-more-id="${id}"][data-load-more-target]`,
        )
        .forEach((loadMoreTriggerElement) => {
          loadMoreTriggerElement.setAttribute('disabled', '');
        });
    }

    document.dispatchEvent(
      new CustomEvent('loadMore', {
        detail: {
          postType,
          elements: [...targetElement.children].slice(
            targetElement.children.length - count,
          ) as HTMLElement[],
        },
      }),
    );
  } catch (error) {
    currentPageMap.set(id, currentPage - 1);
  }
};

export default defineModule(
  () => {
    const loadMoreElements =
      document.querySelectorAll<HTMLElement>('[data-load-more]');

    loadMoreElements.forEach((loadMoreElement) => {
      loadMoreElement.addEventListener('click', loadMore, { passive: true });
    });
  },
  () => {
    uniqueId = 0;
    currentPageMap.clear();
  },
);
