import { defineModule } from '../../utils/helpers';

const getElements = () => ({
  navbarElement: document.querySelector<HTMLElement>('.navbar'),
  navbarNavElement: document.querySelector<HTMLElement>('.navbar__nav'),
  headerContentBlockElement: document.querySelector<HTMLElement>(
    '.content-block--header',
  ),
});

const observer = new IntersectionObserver(
  (entries) => {
    const { navbarElement } = getElements();

    entries.forEach((entry) => {
      if (!navbarElement) return;

      navbarElement.classList.add('navbar--no-transitions');

      if (entry.boundingClientRect.top < 0) {
        navbarElement.classList.add('navbar--scroll');
      } else {
        navbarElement.classList.remove('navbar--scroll');
      }

      setTimeout(() => {
        navbarElement.classList.remove('navbar--no-transitions');
      }, 25);
    });
  },
  {
    root: null,
    threshold: 1,
    rootMargin: '0px',
  },
);

export default defineModule(
  () => {
    const { headerContentBlockElement } = getElements();
    if (!headerContentBlockElement) return;

    observer.observe(headerContentBlockElement);
  },
  () => {},
);
