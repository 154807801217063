import { toggleModal } from '.';
import type { CustomEventMap } from '../../global';
import { defineModule } from '../../utils/helpers';

const getElements = () => ({
  openImageModalElements: document.querySelectorAll<HTMLElement>(
    '[data-open-image-modal]',
  ),
});

const showImageModal = (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  const imageTargetId = e.currentTarget.getAttribute('data-image-target-id');
  if (!imageTargetId) return;

  const imageTargetElement = document.getElementById(imageTargetId);
  if (!imageTargetElement || !(imageTargetElement instanceof HTMLImageElement))
    return;

  const imageModalContentElement = document.querySelector<HTMLElement>(
    '[data-modal="image"] .content__image',
  );
  if (!imageModalContentElement) return;

  const clonedImageElement = imageTargetElement.cloneNode(
    true,
  ) as HTMLImageElement;

  clonedImageElement.setAttribute('sizes', '(max-width: 672px) 100vw, 672px');

  clonedImageElement.removeAttribute('class');
  clonedImageElement.removeAttribute('id');
  clonedImageElement.classList.add('w-full', 'h-full', 'object-cover');

  imageModalContentElement.replaceChildren(clonedImageElement);

  toggleModal('image', true);
};

const onLoadMore = ({ detail: { elements } }: CustomEventMap['loadMore']) => {
  if (!elements.length) return;

  elements.forEach((element) => {
    const openImageModalElements = element.querySelectorAll<HTMLElement>(
      '[data-open-image-modal]',
    );

    openImageModalElements.forEach((openImageModalElement) => {
      openImageModalElement.addEventListener('click', showImageModal, {
        passive: true,
      });
    });
  });
};

export default defineModule(
  () => {
    const { openImageModalElements } = getElements();

    openImageModalElements.forEach((openImageModalElement) => {
      openImageModalElement.addEventListener('click', showImageModal, {
        passive: true,
      });
    });

    document.addEventListener('loadMore', onLoadMore, { passive: true });
  },
  () => {
    const { openImageModalElements } = getElements();

    openImageModalElements.forEach((openImageModalElement) => {
      openImageModalElement.removeEventListener('click', showImageModal);
    });

    document.removeEventListener('loadMore', onLoadMore);
  },
);
