import { defineModule } from '../utils/helpers';

export default defineModule(
  () => {
    if (window.sbi_init) window.sbi_init();
  },
  () => {
    const sbiLightboxes = document.querySelectorAll('div[id^="sbi_lightbox"]');
    sbiLightboxes.forEach((div) => div.remove());
  },
);
