import type Splide from '@splidejs/splide';
import { BREAKPOINTS } from '../utils/breakpoints';
import { defineModule } from '../utils/helpers';
import type { CustomEventMap } from '../global';

const splides: Splide[] = [];

const getElements = () => ({
  projectElements: document.querySelectorAll<HTMLElement>('[data-project]'),
});

const createSplide = async (projectElement: HTMLElement) => {
  const projectSlideElement =
    projectElement.querySelector<HTMLElement>('.splide');
  if (!projectSlideElement) return;

  const { Splide } = await import('@splidejs/splide');

  splides.push(
    new Splide(projectSlideElement, {
      type: 'loop',
      perPage: 1,
      pagination: false,
      updateOnMove: true,
      mediaQuery: 'min',
      breakpoints: {
        [BREAKPOINTS['2xl']]: {
          perPage: 2,
        },
      },
    }).mount(),
  );
};

const onLoadMore = ({ detail: { elements } }: CustomEventMap['loadMore']) =>
  Promise.all(elements.map((element) => createSplide(element)));

export default defineModule(
  () => {
    const { projectElements } = getElements();
    projectElements.forEach(createSplide);

    document.addEventListener('loadMore', onLoadMore, { passive: true });
  },
  () => {
    document.removeEventListener('loadMore', onLoadMore);

    if (!splides.length) return;
    while (splides.length > 0) splides.pop()?.destroy(true);
  },
);
