import { Overlay, hasOpenOverlay, toggleOverlay } from '../../utils/overlays';
import { defineModule, nextTick } from '../../utils/helpers';
import { BREAKPOINT_MEDIA_QUERIES } from '../../utils/breakpoints';

const getElements = () => ({
  navbarElement: document.querySelector<HTMLElement>('.navbar'),
  navbarTogglerElements:
    document.querySelectorAll<HTMLButtonElement>('.navbar__toggler'),
});

const toggleNavbar = (force?: boolean) => {
  const { navbarElement, navbarTogglerElements } = getElements();
  if (!navbarElement) return;

  force = force ?? !hasOpenOverlay(Overlay.MENU);

  toggleOverlay(
    Overlay.MENU,
    navbarElement.classList.toggle('navbar--open', force),
  );

  navbarTogglerElements.forEach((navbarTogglerElement) => {
    navbarTogglerElement.ariaExpanded = String(hasOpenOverlay(Overlay.MENU));
  });
};

const onNavbarTogglerClick = () => toggleNavbar();

const navbarBreakpointChecker = () => {
  const { navbarElement, navbarTogglerElements } = getElements();
  if (!navbarElement) return;

  toggleOverlay(Overlay.MENU, false);

  navbarTogglerElements.forEach((navbarTogglerElement) => {
    navbarTogglerElement.ariaExpanded = hasOpenOverlay(Overlay.MENU)
      ? 'true'
      : 'false';
  });

  navbarElement.classList.add('navbar--no-transitions-all');
  navbarElement.classList.remove('navbar--open');
  nextTick(() => {
    navbarElement.classList.remove('navbar--no-transitions-all');
  });
};

export default defineModule(
  () => {
    const { navbarElement, navbarTogglerElements } = getElements();
    if (!navbarElement) return;

    navbarTogglerElements.forEach((navbarTogglerElement) => {
      navbarTogglerElement.addEventListener('click', onNavbarTogglerClick, {
        passive: true,
      });
    });

    BREAKPOINT_MEDIA_QUERIES.lg.addEventListener(
      'change',
      navbarBreakpointChecker,
      { passive: true },
    );
  },
  () => {
    const { navbarElement, navbarTogglerElements } = getElements();
    if (!navbarElement) return;

    toggleNavbar(false);

    navbarTogglerElements.forEach((navbarTogglerElement) => {
      navbarTogglerElement.removeEventListener('click', onNavbarTogglerClick);
    });

    BREAKPOINT_MEDIA_QUERIES.lg.removeEventListener(
      'change',
      navbarBreakpointChecker,
    );
  },
);
